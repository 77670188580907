import styled from 'styled-components';

const ContentWrapper = styled.div`
  max-width: 1480px;
  width: 100%;
  background-color: #eaeaea;
  margin: 0 auto;
  margin-bottom: ${props => (props.isReducedHeight ? '32px' : '48px')};
  padding: 24px;

  @media screen and (min-width: 768px) {
    padding: 54px;
  }

  .ArticlePage__Date {
    font-family: lato;
    font-size: 32px;
    color: #939598;
    margin-bottom: 24px;
  }

  .ContentWrapper--ContactPage {
    padding: 54px;
  }
`;

export default ContentWrapper;
