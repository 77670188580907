/**
 * Latimer Hinks News Page
 */

import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { request } from 'graphql-request';
import { graphql } from 'gatsby';
import { Grid, Cell } from '../components/global/grid';
import ArticleCard from '../components/common/article-card/ArticleCard';
import Container from '../components/global/container/Container';
import ContentWrapper from '../components/common/content-wrapper/ContentWrapper';
import Hero from '../components/common/hero/Hero';
import Layout from '../components/layout';
import StaticHeroContainer from '../components/atoms/hero/StaticHeroContainer';
import Spinner from '../components/common/spinner/Spinner';
import Button from '../components/common/button/Button';

// Move to another file before merging
const FilterBlock = ({ categories, dates, callback }) => {
  const [selectedCategory, setSelectedCategory] = React.useState();
  const [selectedYear, setSelectedYear] = React.useState();
  const [selectedMonth, setSelectedMonth] = React.useState();
  const [yearOptions, setYearOptions] = React.useState([]);

  React.useEffect(() => {
    if (!selectedYear) {
      fetch(process.env.GATSBY_WP_ARCHIVE)
        .then((res) => {
          if (res.ok) {
            res.json().then((data) => {
              const getYears = () => {
                const years = data.map((item) => item.year);
                return Array.from(new Set(years));
              };

              const yearOpts = getYears();

              setYearOptions(yearOpts);
            });
          } else {
            console.error('Problem calling /archive', res);
          }
        })
        .catch((err) => console.error('Unable to fetch from /archive', err));
    }
  }, [dates]);

  React.useEffect(() => {
    callback({
      year: selectedYear,
      month: selectedMonth,
      category: selectedCategory,
    });
  }, [selectedYear, selectedMonth, selectedCategory]);

  const Wrap = styled.div`
    color: white;
    display: flex;
    padding: 2rem;
    background-color: #00254a;
    width: 100%;
    position: relative;
    z-index: 100;
    margin-top: -56px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 16px;
    margin-bottom: 24px;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      padding: 2rem 2rem 1rem;
    }

    p {
      margin: 0;
    }

    label {
      display: block;
    }

    select {
      width: 100%;

      @media screen and (max-width: 768px) {
        margin-bottom: 1rem;
      }
    }

    .box {
      flex: 1;
      margin: 0 0.5rem;
    }
  `;

  const handleYearChange = (e) => {
    setSelectedYear(e.target.value);
  };

  const handleMonthchange = (e) => {
    setSelectedMonth(e.target.value);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
  };

  return (
    <Wrap>
      <div className="box">
        <label htmlFor="category">Filter by category:</label>
        <select
          style={{ padding: '.5rem 1rem' }}
          id="category"
          name="category"
          onChange={handleCategoryChange}
          value={selectedCategory}
        >
          <option>All categories</option>

          {categories.edges.map((cat) => {
            return (
              <option key={cat.node.id} value={cat.node.slug}>
                {cat.node.name}
              </option>
            );
          })}
        </select>
      </div>

      <div className="box">
        <label htmlFor="year">Filter by published year:</label>
        <select
          style={{ padding: '.5rem 1rem' }}
          id="year"
          name="year"
          value={selectedYear}
          onChange={handleYearChange}
        >
          <option>All years</option>

          {yearOptions.map((option, index) => {
            return (
              <option key={index} value={option}>
                {option}
              </option>
            );
          })}
        </select>
      </div>

      <div className="box">
        <label htmlFor="category">Filter by published month:</label>
        <select
          style={{ padding: '.5rem 1rem' }}
          id="category"
          name="category"
          onChange={handleMonthchange}
          value={selectedMonth}
        >
          <option>All months</option>
          <option value="1">January</option>
          <option value="2">February</option>
          <option value="3">March</option>
          <option value="4">April</option>
          <option value="5">May</option>
          <option value="6">June</option>
          <option value="7">July</option>
          <option value="8">August</option>
          <option value="9">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
      </div>
    </Wrap>
  );
};

const NewsPage = ({ data, path }) => {
  const [pageNumber, setPageNumber] = React.useState(0);
  const [clientArticles, setClientArticles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [filterActive, setFilterActive] = React.useState(false);
  const [activeCategory, setActiveCategory] = React.useState();
  const [activeMonth, setActiveMonth] = React.useState();
  const [activeYear, setActiveYear] = React.useState();
  const [paginationHide, setPaginationHide] = React.useState(false);
  const [paginateLoading, setPaginateLoading] = React.useState(false);

  const {
    allWpPost: { edges },
  } = data;

  React.useEffect(() => {
    requestPosts();
  }, [pageNumber, activeYear, activeMonth, activeCategory]);

  const requestPosts = () => {
    const query = `
      query PaginatePosts($first: Int, $after: String, $category: String, $month: Int, $year: Int) {
        posts(first: $first, after: $after, where: {
          categoryName: $category,
          dateQuery: {
            month: $month,
            year: $year
          }
        }) {
          edges {
            cursor
            node {
              id
              title
              slug
              categories {
                nodes {
                  id
                  slug
                }
              }
              date
              articleAcf {
                featuredImage {
                  sourceUrl
                }
              }
            }
          }
        }
      }
    `;

    // Define the variables
    const variables = {
      category: activeCategory,
      month: activeMonth,
      year: activeYear,
      first: pageNumber === 1 && !filterActive ? 24 : 12,
      after:
        pageNumber >= 1 && clientArticles.length > 0
          ? clientArticles[clientArticles.length - 1].cursor
          : '',
    };

    if (pageNumber >= 1) {
      request(process.env.GATSBY_WP_API, query, variables)
        .then((res) => {
          setLoading(false);
          setPaginateLoading(false);

          if (res.posts) {
            if (res.posts.edges.length > 0) {
              if (paginationHide) {
                setPaginationHide(false);
              }

              // If this is the first page, then ensure we update the array with the
              // correct data
              if (pageNumber === 1 && !filterActive) {
                setClientArticles(
                  res.posts.edges.filter((_, index) => index >= 12)
                );
                setLoading(false);
              } else {
                setClientArticles([...clientArticles, ...res.posts.edges]);
                setLoading(false);
              }
            } else {
              setPaginationHide(true);
            }
          } else {
            console.warn('No posts returned from graphql call', res);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.error('Error in PaginatePosts query', err);
          setLoading(false);
        });
    }
  };

  // Handle filter change
  const handleFilterChange = (params) => {
    const { category, year, month } = params;

    if (category || year || month) {
      setLoading(true);

      // Update the url
      setActiveCategory(category === 'All categories' ? '' : category);
      setActiveYear(parseInt(year));
      setActiveMonth(parseInt(month));

      if (!filterActive) {
        setFilterActive(true);
      }

      setClientArticles([]);
      setPageNumber(1);
    }
  };

  // Iterate the pagination key
  const paginate = () => {
    setPaginateLoading(true);
    setPageNumber(pageNumber + 1);
    requestPosts();
  };

  // Not Found Text
  const NotFoundText = styled.p`
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    flex: 1;
  `;

  return (
    <Layout seo={data.seo} path={path}>
      <Hero
        isLandingPage
        category="generic"
        backgroundImageUrl={
          data.page ? data.page.featuredImage.node.sourceUrl : null
        }
      >
        <Container isFullWidth>
          <StaticHeroContainer category="generic" hideBlock noSideSpacing>
            <div style={{ paddingBottom: 40 }}>
              <h1 className="StaticHeroContainer__Title">
                News &amp; insights
              </h1>
            </div>
          </StaticHeroContainer>
        </Container>
      </Hero>

      <Container>
        <FilterBlock
          callback={handleFilterChange}
          categories={data.categories || []}
          dates={data.dates}
          articles={clientArticles.length > 0 ? clientArticles : edges}
        />
      </Container>

      <Container>
        <ContentWrapper isReducedHeight>
          {loading ? (
            <Spinner />
          ) : (
            <Grid gutter={16}>
              {!filterActive &&
                (edges.length > 0 ? (
                  edges.map((edge) => {
                    const { node } = edge;

                    return (
                      <Cell
                        cellSize={{ widescreen: 6, desktop: 6 }}
                        key={node.id}
                      >
                        <ArticleCard
                          date={node.date}
                          id={node.id}
                          title={node.title}
                          category={node.categories.nodes[0].slug}
                          image={node.articleAcf.featuredImage?.sourceUrl}
                          slug={node.slug}
                          isClientSide={false}
                        />
                      </Cell>
                    );
                  })
                ) : (
                  <NotFoundText>Your search returned no results</NotFoundText>
                ))}

              {clientArticles.length > 0 ? (
                clientArticles.map((article) => {
                  const { node } = article;

                  return node ? (
                    <Cell
                      cellSize={{ widescreen: 6, desktop: 6 }}
                      key={node.id}
                    >
                      <ArticleCard
                        date={moment(node.date).format('Do MMMM YYYY')}
                        id={node.id}
                        title={node.title}
                        category={node.categories.nodes[0].slug}
                        image={node.articleAcf.featuredImage?.sourceUrl}
                        slug={node.slug}
                        isClientSide={false}
                      />
                    </Cell>
                  ) : null;
                })
              ) : filterActive ? (
                <NotFoundText>Your search returned no results</NotFoundText>
              ) : null}
            </Grid>
          )}
        </ContentWrapper>

        {!paginationHide && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingBottom: 32,
            }}
          >
            <Button
              onClick={paginate}
              label={paginateLoading ? 'Please wait...' : 'Load more'}
            />
          </div>
        )}
      </Container>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ArticlesQuery {
    page: wpPage(slug: { eq: "news-events" }) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
      }
      featuredImage {
        node {
          sourceUrl
        }
      }
    }

    categories: allWpCategory {
      edges {
        node {
          id
          name
          slug
        }
      }
    }

    allWpPost(limit: 12, sort: { fields: date, order: DESC }) {
      edges {
        node {
          id
          title
          slug
          categories {
            nodes {
              id
              slug
            }
          }
          date(formatString: "Do MMMM YYYY")
          articleAcf {
            featuredImage {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

export default NewsPage;
